const hamburger = document.querySelector('.hamburger');
const menu = document.querySelector('.header-menu');
const activeHamburgerClass = 'hamburger_active';
const activeMenuClass = 'header-menu_active';
const body = document.body;

const toggleMenu = () => {
  hamburger.classList.toggle(activeHamburgerClass);
  menu.classList.toggle(activeMenuClass);
  body.classList.toggle('hidden');
};

const handleClickMenu = (e) => {
  const target = e.target;
  const isActiveMenu = hamburger.classList.contains(activeHamburgerClass);
  const isMenuLink = target.classList.contains('header-menu__link');

  if (isActiveMenu && isMenuLink) {
    toggleMenu();
  }
};

hamburger.addEventListener('click', toggleMenu);
menu.addEventListener('click', handleClickMenu);
