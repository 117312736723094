class ServicesItem {
  constructor({ src, title, type, descr }) {
    this.src = src;
    this.title = title;
    this.type = type;
    this.descr = descr;

    this.wrapper = document.querySelector('.services-wrapper');
  }

  render() {
    let descrContent;

    if (this.type === 'grid') {
      descrContent = this.descr.map((item) => `<span>${item}</span>`).join('');
    } else {
      descrContent = this.descr.map((item) => `<li>${item}</li>`).join('');
    }

    const item = document.createElement('div');
    item.classList.add('services-item');

    item.innerHTML = `
    <div class="services-item__content">
        <div class="services-item__img">
            <picture>
                <source srcset="img/services/${this.src}.webp" type="image/webp" />
                <img src="img/services/${this.src}.jpg" alt="laser cutting" />
            </picture>
        </div>
        <div class="services-item__title">${this.title}</div>
        <div class="services-item__descr">
            <${this.type === 'grid' ? 'div' : 'ul'} class="services-${this.type}">
            ${descrContent}
            </${this.type === 'grid' ? 'div' : 'ul'}>
        </div>
    </div>  
    <a href="#contact-form">
      <button class="btn">получить расчет</button>
    </a>
      
  `;

    this.wrapper.append(item);
  }
}

const serviceItemData = [
  {
    title: 'ЛАЗЕРНАЯ РЕЗКА КРУГЛЫХ </br> И ПРОФИЛЬНЫХ ТРУБ',
    type: 'grid',
    descr: [
      'В т.ч. резка швеллера, уголка, двутавра',
      'Максимальная длина:',
      '',
      '- заготовки',
      '6 000 мм',
      '- детали',
      '5 500 мм',
      'Диаметр трубы:',
      '20...160',
      'Размер профиля:',
      '20x20...110x110 мм',
      'Толщина стенки трубы:',
      'до 14 мм',
      'Макс.масса трубы:',
      'до 300 кг',
      'Точность реза:',
      '0,1 мм',
    ],
  },
  {
    title: 'ГИБКА ЛИСТОВОГО </br> МЕТАЛЛА НА ПРЕССЕ',
    type: 'grid',
    descr: [
      'Максимальная длина',
      'заготовки:',
      '2 600 мм',
      'Максимальная толщина листа заготовки:',
      '',
      '- алюминий',
      '12 мм',
      '- сталь',
      '10 мм',
      '- нержавейка',
      '6 мм',
      'Возможность догиба:',
      'до 180&#176;',
      'Точность гиба:',
      'до 1&#176;',
    ],
  },
  {
    title: 'ЛАЗЕРНАЯ РЕЗКА ЛИСТОВОГО </br> МЕТАЛЛА',
    type: 'grid',
    descr: [
      'Максимальный размер',
      'заготовки:',
      '1 500 х 3 000',
      'Сталь',
      'до 14 мм',
      'Нержавеющая сталь',
      'до 6 мм',
      'Алюминиевые сплавы',
      'до 4 мм',
      'Латунь',
      'до 1,5 мм',
      'Точность реза',
      'до 0,1 мм',
      'Резка воздухом,',
      'до 1801&#176;',
      'кислородом, азотом',
      'до 11&#176;',
    ],
  },
  {
    title: 'ПОРОШКОВАЯ ПОКРАСКА </br> МЕТАЛЛОКОНСТРУКЦИЙ',
    type: 'list',
    descr: [
      'Высокая производительность: до 150 деталей в час',
      'Максимальные габариты изделия: 6100х1700х1500 мм',
      'Порошковая краска европейских производителей',
      'Огромный выбор красок различных по цвету и текстуре',
      'Возможно предварительное покрытие цинкогрунтом',
    ],
  },
  {
    title: 'СВАРКА МЕТАЛЛА',
    type: 'list',
    descr: [
      'Из нержавеющей стали и алюминия',
      'Несколько постов для разных видов сварки',
      'Ручная и полуавтоматическая сварка',
      'Сварка в среде защитного газа',
      'Проектирование документации по сварочным работам',
    ],
  },
  {
    title: 'ИЗГОТОВЛЕНИЕ </br> МЕТАЛЛОКОНСТРУКЦИЙ',
    type: 'list',
    descr: [
      'Производство, проектирование, резка, гибка, мехобработка, сварка, порошковая покраска',
      'Работа с разными видами металла (в т.ч. нержавеющая сталь и алюминий)',
      'Технологическое сопровождение каждого этапа производства',
      'Производство согласно требованиям конструкторской документации',
      'Контроль качества и гарантия',
    ],
  },
];

serviceItemData.forEach((item, i) => {
  new ServicesItem({ src: i + 1, ...item }).render();
});
